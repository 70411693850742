<template>
  <div class="MyRegistration">
    <div v-loading="loading"></div>
    <div v-if="!Isdata" class="MyActivityList">
      <div v-for="(item, index) in MyActivity.data" :key="item.id" class=" MyActivity-item" :class="'MyActivity-item' + (index + 1)">
        <div class="flex3 MyActivity-item-d">
          <div class="MyAct-item-l1 flex">
            <div class="MyAct-item-img cur" @click="GoDetails(item.id)">
              <img v-if="item.imgUrl" :src="item.imgUrl" alt="">
              <img v-else src="../../assets/PC/keenMind/list0.jpg" alt="">
            </div>
            <div class="MyAct-title-date hidden flex7">
              <p class="MyAct-item-title hidden cur" @click="GoDetails(item.id)"> {{ item.title }} </p>
              <p class="MyAct-item-date co-666 hidden"> {{ item.startTimeStr }} - {{item.endTimeStr}} </p>
            </div>
          </div>
          <div v-if="clientWidth > 750" class="MyAct-item-industrys tc">
            <span>{{ item.tags.join('、') }}</span>
          </div>
          <div class="MyAct-item-states hidden">
            <div v-if="item.status == 0" class="RecordList-item-state tr co-999">未开始</div>
            <div v-if="item.status == 1" class="RecordList-item-state tr co-voi">进行中</div>
            <div v-if="item.status == 2" class="RecordList-item-state tr co-999">已结束</div>
            <div v-if="clientWidth < 750" class="MyAct-item-industrys-mb hidden">
              <span>{{ item.tags.join('、') }}</span>
            </div>
          </div>
          <div v-if="clientWidth > 750" class="MyAct-item-detail cur" @click="GoDetails(item.id)">
            活动详情
          </div>
        </div>
        <div v-if="clientWidth < 750" class="RecordList-item-track-mb tr" @click="GoDetails(item.id)">活动详情</div>
      </div>
    </div>
    <div v-else class="noData">
      <img src="@/assets/PC/profile/listNone.png" alt="" />
    </div>
    <div class="page tr">
      <el-pagination
        @current-change="handleCurrentChange"
        :total="MyActivity.recordCount"
        :page-size="MyActivity.pageSize"
        :current-page="MyActivity.pageIndex"
        background
        layout="pager, next"
        next-text="下一页"
        hide-on-single-page>
      </el-pagination>
    </div>
    <div v-if="IsloadList" class="loadList flex0">
      <div class="loadList0" @click="loadlist">加载更多</div>
    </div>
    <div v-if="clientWidth < 750 && !IsloadList && MyActivity.data.length > 0" class="tc notDetail">
      <div>
        没有更多内容了~
        <span class="violet" @click="scrollTop">返回顶部</span>
      </div>
    </div>
  </div>
</template>

<script>
import { GetMyActivity } from "@/api/profile.js";
import { dataState } from "@/api/all.js";
import { GetWxShares } from "@/utils/common";

export default {
  name: "MyRegistration",
  data() {
    return {
      loading: true,                          // !loading
      pages:{page: 1,limit: 5,},              // !分页参数
      MyActivity: {data: [], recordCount: 0}, // !兑换记录数组
      IsloadList: false,                      // !移动端加载更多
      Isdata: false,                          // !暂无数据
    }
  },
  mounted() {
    const share = {
      title: '个人中心 | 剑盟社区',
      desc: ''
    }
    GetWxShares(share);
    this.getInit();
    // 数据统计
    const data ={
      "visitType": 1,
      "gid": sessionStorage.getItem('gid') ? sessionStorage.getItem('gid') : '',
      "url": window.location.href,
      "pageUrl": "MyRegistration",
      "relationId": ""
    }
    dataState(data).then(res => {
      sessionStorage.setItem('gid',res.data);
    })
  },
  methods: {
    // !初始化列表
    getInit(Ismobile) {
      GetMyActivity(this.pages).then(res=> {
        if(res.status == 1){
          if(res.data.length > 0){
            this.Isdata = false;
            if(res.data.length < res.recordCount){
              this.IsloadList = true;
            }
            if(Ismobile && this.clientWidth < 750){
              this.MyActivity.data = this.MyActivity.data.concat(res.data);
              if(this.MyActivity.data.length == res.recordCount) {
                this.IsloadList = false;
              }
            } else {
              this.MyActivity = res;
            }
          }else {
            this.MyActivity = {data: [], recordCount: 0};
            this.IsloadList = false;
            this.Isdata = true;
          }
          
        }else {
          this.$toast(res.message);
        }
        this.loading = false;
      })
    },
    // !PC端分页
    handleCurrentChange(currentPage){
      this.loading =true;
      this.pages.page = currentPage;
      this.getInit();
    },
    // !点击加载更多
    loadlist() {
      if(this.clientWidth < 750){
        this.loading = true;
        this.pages.page++;
        this.getInit(true);
      }
    },
    // !返回顶部
    scrollTop() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果 
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 100;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
    },
    // !去活动详情页面
    GoDetails(id) {
      this.$router.push('/Home/keenMindDetail?id=' + id);
    }
  },
  computed: {
    clientWidth() {
      return this.$store.state.clientWidth
    }
  }
}
</script>

<style lang="scss" scoped>
  $mobil_base: 750;
  @function vw($true) {
    @return ($true / $mobil_base) * 100vw;
  }

  .MyRegistration {
    padding: 40px;
    .MyActivityList { border: 1px solid #DBC3E7; margin-bottom: 20px;  }
    .MyActivity-item-d {padding: 25px; border-bottom: 1px solid #DBC3E7; background: #F5EFF8; max-width: 895px;}
    .MyAct-item-img {width: 80px; min-width: 80px; margin-right: 12px;}
    .MyAct-item-img img {width: 100%;}

    .MyAct-title-date  p{width: 100%;}
    .MyAct-item-title, .MyAct-item-industrys span, .MyAct-item-states div, .MyAct-item-detail {font-size: 16px;}
    .MyAct-item-date {font-size: 11px;}
    .MyAct-item-detail {text-decoration: underline; min-width: 64px;}

    .MyAct-item-l1 {width: 40%;}
    .MyAct-item-industrys {width: 30%;}
  }
  @media (max-width: 750px) {
    .MyRegistration {
      padding: vw(23);
      .MyActivity-item-d {padding: vw(25); border-bottom: 0;}
      .MyAct-item-img {width: vw(85); min-width: vw(85); }

      .MyAct-item-l1 { width: auto; max-width: 80%;}
      .MyAct-item-title {font-size: vw(27);}
      .MyAct-item-date {font-size: vw(17);}

      .MyAct-item-industrys-mb span{font-size: vw(21);}
      .MyAct-item-detail, .MyAct-item-industrys {display: none;}
      .RecordList-item-track-mb{margin-top: 1px;background: #F5EFF8; height: vw(73);font-size: vw(25); border-bottom: 1px #DBC3E7 solid;line-height: vw(73); padding-right: vw(20); text-decoration: underline;}
      .MyAct-item-states {min-width: vw(69);max-width: 30%;}
      .MyAct-item-states div{font-size: vw(23);white-space: nowrap;}

    }
  }
</style>